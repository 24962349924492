import * as React from 'react';

const ContactCTA = () => (
  <article className="w-full max-w-5xl pt-12 mx-auto my-auto">
    <h1 className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      <span>
        Let’s get <br />
        you{' '}
      </span>
      <span className="text-brand-orange">moving</span>
    </h1>
    <div className="max-w-xs mt-6">
      <h2 className="text-xl font-semibold capitalize">Contact us today</h2>
      <p>Fill in the form below, and we'll be in touch ASAP.</p>
    </div>
  </article>
);

export default ContactCTA;
