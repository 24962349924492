import * as React from 'react';
import { useEffect } from 'react';

import { Hero, Layout, SEO } from '../../components';
import ContactBanner from '../../components/contact/contact-banner';
import CTA from '../../components/contact/contact-cta';
import HeroImage from '../../components/contact/contact-hero-image';
import ContactForm from '../../components/forms/contact-form';
import Map from '../../components/map';

const ContactPage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      var f = document.createElement("iframe");   
f.src = 'https://forms.zohopublic.com.au/frontlineremovals/form/FrontlineRemovals/formperma/hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM?zf_rszfm=1';
f.style.border="none";                                           
f.style.height="655px";
f.style.width="100%";
f.style.zIndex="50";
f.style.transition="all 0.5s ease";// No I18N
var d = document.getElementById("contact-page-bottom-form");
d.appendChild(f);
// window.addEventListener('message', function (){
// var zf_ifrm_data = typeof event.data === 'string' ? event.data.split("|") : null ;
// var zf_perma = zf_ifrm_data && zf_ifrm_data[0];
// // var zf_ifrm_ht_nw = zf_ifrm_data && z( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
// var iframe = document.getElementById("zf_div_hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM").getElementsByTagName("iframe")[0];
// if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
// // var prevIframeHeight = iframe.style.height;
// // if ( prevIframeHeight != zf_ifrm_ht_nw ) {
// // iframe.style.height = zf_ifrm_ht_nw;
// // }   
// }
// }, false);
      }
  
}, []);

  return(
  <Layout hideForm>
    <SEO
      title="Contact Us | Frontline Removals"
      description="For moving enquiry, please feel free to contact us here and chat with our friendly team: We'll follow up within 24 hours (Monday – Friday) Typical response: within 2 hours."
    />
    <Hero image={<HeroImage />} cta={<CTA />} />
    {/* <ContactForm
      formName="contact-page-bottom-form"
      formTitle="Chat with our friendly team"
      className="-mt-24"
    /> */}
    <div id="contact-page-bottom-form" className='relative max-w-4xl mx-auto -mt-48'></div>
    <Map />
    <ContactBanner />
  </Layout>
)};

export default ContactPage;
